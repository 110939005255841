.header-background--rv {
  background-color: $gray-light !important;
}

.header--rv {
  .logo {
    width: auto;
  }

  .main-menu-sections--open {
    max-height: 17rem;
  }

  .header-menu-button {
    font-size: 1.125rem;
  }
}

.footer-background--rv {
  background-color: $gray-light !important;

  .footer-sections {
    width: fit-content;
    margin-left: auto;
  }
}
